<template>
  <v-dialog v-model="confirm" width="300" persistent>
    <v-card>
      <v-card-title class="font-weight-bold">{{ text }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close('no')"> No </v-btn>
        <v-btn color="primary" @click="close('yes')"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    confirm: Boolean,
    text: String,
    close: Function,
  },
};
</script>
