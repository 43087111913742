<template>
  <div>
    <v-img :src="cover" height="250" aspect-ratio="16/9">
      <div class="d-flex justify-center align-center backdrop-grad">
        <div class="display-3 white--text">SHOPPING CART</div>
      </div>
    </v-img>

    <v-container v-if="carts.length > 0" class="my-6">
      <v-sheet v-for="(cart, i) in carts" :key="i">
        <v-row>
          <v-col cols="4" sm="2">
            <v-img
              :src="cart.image[0]"
              :lazy-src="cart.image[0]"
              aspect-ratio="1"
              style="border: 2px solid #c3c3c3; border-radius: 8px"
            ></v-img>
          </v-col>
          <v-col cols="8" sm="7">
            <div class="title font-weight-bold">{{ cart.plant }}</div>
            <div class="body-1 success--text">${{ cart.price }}</div>
            <div class="body-2">Available Stock : {{ cart.stok }}</div>
          </v-col>
          <v-col cols="12" sm="3">
            <div v-if="isMobile()" class="d-flex" style="gap: 0 10px">
              <v-text-field
                v-model="cart.qty"
                label="Quantity"
                type="number"
                outlined
                dense
                min="1"
                class="flex-grow-1"
                :max="cart.stok"
                @change="calculatePrice(cart, i)"
              ></v-text-field>

              <v-btn color="accent" @click="removeCart(cart, i)">REMOVE</v-btn>
            </div>
            <div v-else>
              <div class="d-flex justify-end">
                <v-text-field
                  v-model="cart.qty"
                  label="Quantity"
                  type="number"
                  outlined
                  dense
                  min="1"
                  :max="cart.stok"
                  style="max-width: 100px"
                  @change="setQuantity(cart, i)"
                ></v-text-field>
              </div>
              <div>
                <v-btn color="accent" block @click="removeCart(cart, i)"
                  >REMOVE</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider
          v-if="carts.length > 1 && carts.length !== i + 1"
          class="my-6"
        ></v-divider>
      </v-sheet>

      <PopConfirm
        text="Are you sure remove this product?"
        :confirm="deleteConfirm"
        :close="(val) => deleteAction(val)"
      />

      <v-sheet class="mt-12">
        <div class="body-1 font-weight-bold mb-4">Message For Seller</div>
        <v-textarea
          v-model="messageForSeller"
          rows="3"
          outlined
          auto-grow
          placeholder="Your message"
        ></v-textarea>

        <div class="d-flex justify-end text-right">
          <div>
            <div class="display-2 font-weight-bold">
              ${{ calculateTotal() }}
            </div>
            <v-btn color="success px-12 mt-2" large @click="checkoutHandler"
              >CHECKOUT</v-btn
            >
            <div class="body-2 mt-1">Shipping calculated at checkout.</div>
          </div>
        </div>
      </v-sheet>
    </v-container>

    <v-container v-if="carts.length === 0" class="my-6 text-center">
      <div class="display-1 font-weight-thin">
        Product has not been added to cart
      </div>
      <v-btn text class="primary--text mt-2" to="catalog">Go to Catalog</v-btn>
    </v-container>

    <PopupLogin :isOpen="popupLogin" :close="() => (popupLogin = false)" />
  </div>
</template>

<script>
import store from "@/store";
import PopConfirm from "@/components/PopConfirm.vue";
import PopupLogin from "@/components/PopupLogin.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PopConfirm,
    PopupLogin,
  },
  data() {
    return {
      cover: "",
      carts: [],
      deleteData: null,
      deleteConfirm: false,
      popupLogin: false,
      messageForSeller: "",
    };
  },
  computed: {
    ...mapGetters(["g_cart", "g_user", "g_transaction", "g_profile"]),
  },
  async mounted() {
    await this.setData();
    this.messageForSeller = this.g_transaction.transaction.message;
    this.cover = this.g_profile.home_cover;
  },
  methods: {
    async setData() {
      const DATA = this.g_cart;
      if (DATA && DATA.length > 0) {
        this.carts = DATA;
        this.carts.map((r) => {
          r.total = this.calculatePrice(r);
        });
      }
    },
    setQuantity(cart, i) {
      const NEWDATA = [...this.carts];
      const product = this.carts.filter((r) => r.id === cart.id);

      if (product.length > 0) {
        const totalQty = product.map((r) => r.qty);
        const totalStock = product.map((r) => r.stok);
        if (totalQty <= totalStock) {
          NEWDATA[i].qty = Number(cart.qty);
          store.commit("SET_CART", NEWDATA);
        }
      }
      this.calculatePrice(cart, i);
    },
    calculateTotal() {
      let total = 0;
      this.carts.map((r) => {
        total = total + r.total;
      });
      return total;
    },
    calculatePrice(item, idx) {
      if (idx !== undefined) {
        if (Number(item.qty) >= item.stok) {
          this.carts[idx].qty = item.stok;
        }
        const total = Number(item.price) * Number(item.qty);
        this.carts[idx].total = total;
      } else return Number(item.price) * Number(item.qty);
    },
    removeCart(item, idx) {
      this.deleteConfirm = true;
      this.deleteData = {
        ...item,
        index: idx,
      };
    },
    deleteAction(value, idx) {
      this.deleteConfirm = false;
      if (value === "yes") {
        this.carts.splice(idx, 1);
        store.commit("SET_CART", this.carts);
        store.commit("CALL_SYSTEMBAR", {
          msg: "Success remove product from cart",
          color: "success",
        });
      }
      this.$nextTick(() => (this.deleteData = null));
    },
    checkoutHandler() {
      if (this.g_user.id) {
        const transaction = {
          type: "transaction",
          transaction: {
            subtotal: this.calculateTotal(),
            tax: null,
            shipping_cost: null,
            total: this.calculateTotal(),
            message: this.messageForSeller,
          },
          transaction_detail: this.carts.map((c) => {
            return {
              item: c.id,
              quantity: c.qty,
              price: c.total,
            };
          }),
        };

        store.commit("SET_CHECKOUT", transaction);
        return this.$router.push("/checkout");
      }
      this.popupLogin = true;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
